import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
	render() {
		return (
			<div className="hc-footer top-shadow">
				<Link className="home-page-a" to="/"><img className="footer-logo" src="/images/HallmarkMedia_Logo_White.png" alt="Hallmark Channel Logo" />
				</Link>
				<div className="footer-links-container">
					<div className="btmfooter footer-links">
						<a href="https://www.hallmarkchannel.com" target="_blank" rel="noopener noreferrer" className="btmfooter">Hallmark Channel</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkmystery.com" target="_blank" rel="noopener noreferrer" className="btmfooter">Hallmark Mystery</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkfamily.com/" target="_blank" rel="noopener noreferrer" className="btmfooter">Hallmark Family</a>
						&nbsp;|&nbsp;
						<Link className="btmfooter" rel="noopener noreferrer" to='/about-us'>About Us</Link>
						&nbsp;|&nbsp;
						<Link className="btmfooter" rel="noopener noreferrer" to='/careers'>Careers</Link>
						&nbsp;|&nbsp;
						<Link className="btmfooter" rel="noopener noreferrer" to='/contacts'>Contacts</Link>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/faq" target="_blank" rel="noopener noreferrer" className="btmfooter">FAQ</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/advertising" target="_blank" rel="noopener noreferrer" className="btmfooter">Advertising</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkmediainternational.com/" target="_blank" rel="noopener noreferrer" className="btmfooter">International</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannelpress.com/" rel="noopener noreferrer" target="_blank" className="btmfooter">Press</a>
						<br />
						<a href="https://www.hallmarkchannel.com/channel-locator" target="_blank" rel="noopener noreferrer" className="btmfooter">Channel Locator</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/newsletter" target="_blank" rel="noopener noreferrer" className="btmfooter">Newsletter</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/privacy-policy" target="_blank" rel="noopener noreferrer" className="btmfooter">Privacy Policy</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/crown-media-family-networks-terms-of-use" rel="noopener noreferrer" target="_blank" className="btmfooter">Terms Of Use</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/ca-privacy-policy" target="_blank" rel="noopener noreferrer" className="btmfooter">CA Privacy Notice</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmark.com/" target="_blank" rel="noopener noreferrer" className="btmfooter">Hallmark Cards</a>
						&nbsp;|&nbsp;
						<a href="https://www.hallmarkchannel.com/accessibility-notice" target="_blank" rel="noopener noreferrer" className="btmfooter">Accessibility</a>
						&nbsp;|&nbsp;
						<button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{ border: "0", marginLeft: "5px", paddingLeft: "0px", fontSize: "14px", backgroundColor: "#582c83", }}>Cookie Preferences</button>

					</div>
					<div className="footer-copywrite">
						<span className="btmfooter">Copyright © 2023 Hallmark Media, all rights reserved.</span>
					</div>
				</div>
			</div>
		)
	}
}

export default Footer;