import React, { Component } from 'react';
import SubNav from './components/SubNav';
import $ from "jquery";

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "corporate": [],
            "viewer": [],
            "resourceRep": [],
            "locations": [],
        };
        this.eachCorporate = this.eachCorporate.bind(this);
        this.eachViewer = this.eachViewer.bind(this);
        this.eachResourceRep = this.eachResourceRep.bind(this);
        this.eachLocation = this.eachLocation.bind(this);
    }

    componentDidMount() {
        Promise.all([
            fetch('https://corpcms.cminternalcontrol.com/api/CorporateContacts'),
            fetch('https://corpcms.cminternalcontrol.com/api/viewerservices'),
            fetch('https://corpcms.cminternalcontrol.com/api/humanresources'),
            fetch('https://corpcms.cminternalcontrol.com/api/Officelocations'),
        ])
        .then(response => Promise.all(response.map(r => r.json())))
        .then(([res1, res2, res3, res4]) => {
            this.setState({status: 'fetched',
            corporate: res1,
            viewer: res2,
            resourceRep: res3,
            locations: res4
            }); 

        })
    }


    eachCorporate(contact, i) {
        console.log()
        return (
            <div className='col-md-6' key={i}>
                <h3><strong>{contact.name}</strong></h3>
                <p className="contact-p">{contact.position}</p>
                <a className='contact-a' href={`mailto.${contact.email}`}>{contact.email}</a>
            </div>
        );
    }

    eachViewer(viewer, i) {
        return (
            <div className='col-md-6' key={i}>
                <h3><strong>{viewer.name}</strong></h3>
                <p className="contact-p">{viewer.phoneNumber}</p>
                <a className='contact-a' href={`mailto.${viewer.email}`}>{viewer.email}</a>

            </div>
        );
    }

    eachResourceRep(resourceRep, i) {
        return (
            <div className='col-md-6 contact-div' key={i}>
                <a className='contact-a' href={`mailto.${resourceRep.email}`}>{resourceRep.email}</a>
            </div>
        );
    }

    eachLocation(location, i) {
        return (
            <div className='col-md-4' key={i}>
                <h3><strong>{location.city}</strong></h3>
                <p className={`contact-p location${i}`}>{location.address}</p>
                <p className="contact-p">{location.phoneNumber}</p>
            </div>
        );
    }

    render() {
        return (
            <div>
                <SubNav currentActive={"CONTACTS"} />
                <div className="container contacts-container below-fixed-subnav below-about-subnav">
                    <div className="row contacts-row">
                        <h2>CORPORATE CONTACTS</h2>
                        <hr className="hr-no-margin" />
                        <br />
                        {this.state.corporate.map(this.eachCorporate)}
                    </div>
                    <div className="row contacts-row">
                        <h2>HUMAN RESOURCES</h2>
                        <hr className="hr-no-margin" />
                        <br />
                        {this.state.resourceRep.map(this.eachResourceRep)}
                    </div>
                    <div className="row contacts-row">
                        <h2>VIEWER SERVICES</h2>
                        <hr className="hr-no-margin" />
                        <br />
                        {this.state.viewer.map(this.eachViewer)}
                    </div>
                    <div className="row contacts-row locations-container">
                        <h2>OFFICE LOCATIONS</h2>
                        <hr className="hr-no-margin" />
                        <br />
                        <div className="">
                            {this.state.locations.map(this.eachLocation)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;